<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <Bot v-for="(bot, key) in bots" :key="key" :bot="bot" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Bot from "@/views/Bots/Bot";
export default {
  components: {Bot},
  data() {
    return {
      bots: [],
      errors: null,
    }
  },
  created() {
    this.getBots()
  },
  methods: {
    getBots(){
      this.errors = null
      this.$store.state.loading = true
      this.$http({
        method: 'get',
        url: '/v1/bots',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
          Authorization: `${this.$store.state.access.token_type} ${this.$store.state.access.token}`,
        }
      }).then(response => {
        this.bots = response.data.bots
      }).catch(err => {
        this.errors = err.response.data
      }).finally(() => {
        this.$store.state.loading = false
      })
    }
  },
  Components:{
    Bot
  }
}
</script>

<style lang="scss">
.bot-figure {
  width: 750px;
  height: 500px;
  position: absolute;
  left: 0;
  right: 40%;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media only screen and (max-width: 1880px) {
  .bot-figure {
    width: 600px;
  }
}

@media only screen and (max-width: 1392px) {
  .bot-figure {
    width: 450px;
  }
}
</style>
