<template>
  <div>
    <v-card class="rounded-xl" elevation="1">
      <div class="pa-5">
        <v-row>
          <v-col cols="6" class="text-left font-weight-bold">
            <v-chip v-if="bot.live_version" class="text-uppercase" label>Version {{ bot.live_version.version_num }}</v-chip>
            <v-chip v-else class="text-uppercase" label>No Version Found</v-chip>
          </v-col>
          <v-col cols="6">
            <div class="text-right">
              <v-chip v-if="bot.running" class="text-uppercase" label color="success">Running</v-chip>
              <v-chip v-else class="text-uppercase" label color="#e90000">Stopped</v-chip>
            </div>
          </v-col>
        </v-row>
        <div class="text-center mt-3">
          <v-avatar size="120" color="rgb(221 221 221)" class="">
            <v-img src="/bot-avatar.png" class="mx-auto" contain/>
          </v-avatar>
        </div>
        <v-list-item-content>
          <v-list-item-title class="text-h5 mb-1 font-weight-bold">
            {{ bot.name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ bot.token }}</v-list-item-subtitle>
        </v-list-item-content>
      </div>
      <v-card-actions>
        <v-btn
          class="mx-auto"
          outlined
          text
          @click.prevent="$router.push({name:'bot-details', params: {token: bot.token}})"
        >
          View Details
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "Bot",
  props: {
    bot: {
      type: Object,
      default: null,
    }
  }
}
</script>

<style scoped>

</style>
